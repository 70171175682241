<template>
  <div>
    <v-dialog
        v-model="dialog_open"
        width="500"
        persistent
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Поиск
        </v-card-title>

        <v-card-text class="pt-2">
          <div class="flex100row " style="align-items: baseline">
            <div class="flex1">
              <v-text-field hide-details dense outlined label="Строка поиска" @keyup.enter="search" v-model="searchString"></v-text-field>
              <v-checkbox hide-details dense v-model="anycase" label="регистронезависимый поиск"></v-checkbox>
            </div>
            <div>
              <v-btn @click="search">Найти</v-btn>
            </div>
          </div>
          <div style="padding: 15px 10px">
            <ol>
              <li v-for="(e,i) in searchRes">
                <div style="cursor: pointer; margin-bottom: 4px; display: inline-block; border-bottom: 1px dashed #ccc"
                     @click="e.is_open = !e.is_open">{{ e.title }}
                </div>
                <div v-if="e.is_open" v-for="ob in e.obj "
                     style="font-size: 80%; padding: 5px; background-color: #ecd9b6a6; max-height: 200px; overflow: auto; "
                     v-html="ob.text">

                </div>
              </li>
            </ol>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="primary"
              text
              @click="close_dialog()"
          >
            Закрыть
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
let md5 = require('locutus/php/strings/md5')
export default {
  name: "search_dialog",
  props: ['dialog_open', 'change_dialog'],
  data() {
    return {
      searchString: "",
      searchRes: [],
      anycase: false,
    }
  },
  methods: {
    close_dialog() {
      this.change_dialog(false);
    },
    get_find(rd) {
      const vm2 = this;
      return new Promise(async resolve => {
        let key = getCookie('hash') + " " + md5(getCookie('hashp')) + " " + rd.doc_id;
        let s = await CryptoJS.AES.decrypt(rd.db_datas, key);
        let obj = JSON.parse(s.toString(CryptoJS.enc.Utf8));
        if (obj[0].text.toLowerCase().includes(vm2.searchString.toLowerCase()) || obj[1].text.toLowerCase().includes(vm2.searchString.toLowerCase())) {
          if (vm2.anycase) {
            obj[0].text = obj[0].text.toLowerCase().replaceAll(vm2.searchString.toLowerCase(), '<span style="background: yellow">' + vm2.searchString.toLowerCase() + '</span>');
            obj[1].text = obj[1].text.toLowerCase().replaceAll(vm2.searchString.toLowerCase(), '<span style="background: yellow">' + vm2.searchString.toLowerCase() + '</span>');
          } else {
            obj[0].text = obj[0].text.replaceAll(vm2.searchString, '<span style="background: yellow">' + vm2.searchString + '</span>');
            obj[1].text = obj[1].text.replaceAll(vm2.searchString, '<span style="background: yellow">' + vm2.searchString + '</span>');
          }
          let title = _.find(vm2.$store.state.db_titles, {'id': rd.doc_id}).title;
          obj = {obj, ...{'title': title, 'is_open': false}};
          resolve(obj);
        } else {
          resolve(false);
        }
      });
    },
    search() {
      let vm2 = this;
      if (vm2.searchString.length >= 2) {
        axios.get(vm2.$store.state.api_path + '?f=get_doc4search&user_id=' + vm2.$store.state.user)
            .then(async function (response) {
              let out = [];
              if (response.data.length > 0) {
                for await(let e of response.data) {
                  let gf = await vm2.get_find(e);
                  if (gf) out.push(gf);
                }
                vm2.searchRes = out;
              }

            })
      }
    }
  }
}
</script>

<style scoped>

</style>