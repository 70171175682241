<template>
  <div>
    <div class="flex100row cont">
      <div class="ycol-bg left-pan">
        <div class="left-pan_box">
          <div class="catalog" v-for="(d,i) in cat" :key="i">
            <div class="elm flex100row ats-c" v-if="d.parent_id == 0">
              <div>
                <v-icon light @click="d.open = !d.open; set_open(d.id, d.open)" size="32" :class="(d.open)? 'rot90':''">
                  mdi-menu-right
                </v-icon>
              </div>
              <div class="cursor" @click="d.open = !d.open; set_open(d.id, d.open)" v-html="d.title"></div>
              <div>
                <v-menu right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" class="cursor" size="32">mdi-dots-vertical</v-icon>
                  </template>
                  <v-list>
                    <v-list-item @click='create_folder(d.id)' class="hovered cursor">
                      <v-list-item-title>Создать новый раздел внутри этого</v-list-item-title>
                    </v-list-item>
                    <v-list-item class="hovered cursor">
                      <v-list-item-title @click="create_folder(d.parent_id)">Создать новый раздел на одном уровне с
                        текущим
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item @click='create_doc(d.id)' class="hovered cursor">
                      <v-list-item-title>Создать новый документ в этом разделе</v-list-item-title>
                    </v-list-item>
                    <v-list-item class="hovered cursor">
                      <v-list-item-title @click='rename_item(d.id, d.title)'>Переименовать раздел</v-list-item-title>
                    </v-list-item>
                    <v-list-item class="hovered cursor">
                      <v-list-item-title @click="delete_item(d.id)">Удалить раздел со всеми подразделами и документами,
                        входящими в него
                      </v-list-item-title>
                    </v-list-item>

                  </v-list>
                </v-menu>
              </div>
            </div>
            <div class="sub1" v-show="d.open">
              <div v-for="(dd,ii) in d.children" :key="ii">
                <template v-if="dd.parent_id == d.id">
                  <div>
                    <div v-if="dd.is_folder" class="elm sub flex100row ats-c">
                      <div style="padding-left: 15px">
                        <v-icon light @click="dd.open = !dd.open; set_open(dd.id, dd.open)" size="30" :class="(dd.open)? 'rot90':''">
                          mdi-menu-right
                        </v-icon>
                      </div>
                      <div class="cursor" @click="dd.open = !dd.open;set_open(dd.id, dd.open) " v-html="dd.title"></div>
                      <div>
                        <v-menu right>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on" class="cursor" size="30">mdi-dots-vertical</v-icon>
                          </template>
                          <v-list>
                            <v-list-item class="hovered cursor">
                              <v-list-item-title @click='create_folder(dd.parent_id)'>Создать новый раздел на
                                одном уровне с текущим
                              </v-list-item-title>
                            </v-list-item>
                            <v-list-item @click='create_doc(dd.id)' class="hovered cursor">
                              <v-list-item-title>Создать новый документ в этом разделе</v-list-item-title>
                            </v-list-item>
                            <v-list-item class="hovered cursor">
                              <v-list-item-title @click='rename_item(dd.id, dd.title)'>Переименовать раздел</v-list-item-title>
                            </v-list-item>
                            <v-list-item class="hovered cursor">
                              <v-list-item-title @click="delete_item(dd.id)">Удалить раздел со всеми документами,
                                входящими в него
                              </v-list-item-title>
                            </v-list-item>

                          </v-list>
                        </v-menu>
                      </div>
                    </div>
                    <div v-for="(ddd,iii) in dd.children" :key="iii">
                      <template v-if="ddd.parent_id == dd.id && !ddd.is_folder && dd.parent_id == d.id">
                        <div class="sub2" v-show="dd.open">
                          <div
                              :class="'elm text flex100row ats-c '+(($route.params.doc && $route.params.doc == ddd.id)? 'active ' + $store.commit(`set_current_doc`, ddd.title) :'') ">
                            <div style="padding-left: 30px; padding-right: 5px;">
                              <v-icon color=" color: #596283;" light>mdi-file-document-outline</v-icon>
                            </div>
                            <div @click='$router.push({ name: "document3", params: { level1: dd.parent_id, level2: ddd.parent_id, doc: ddd.id } }); toggle_left(); $store.commit("set_current_doc", ddd.title); '
                                 class="cursor"
                                 v-html="ddd.title"></div>
                            <div>
                              <v-menu right>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon v-bind="attrs" v-on="on" class="cursor" size="30">mdi-dots-vertical</v-icon>
                                </template>
                                <v-list>

                                  <v-list-item class="hovered cursor">
                                    <v-list-item-title @click='rename_item(ddd.id, ddd.title)'>Переименовать документ</v-list-item-title>
                                  </v-list-item>
                                  <v-list-item class="hovered cursor">
                                    <v-list-item-title @click="delete_item(ddd.id)">Удалить документ

                                    </v-list-item-title>
                                  </v-list-item>

                                </v-list>
                              </v-menu>
                            </div>

                          </div>
                        </div>

                      </template>
                    </div>
                  </div>
                  <!-- level2 docs -->
                  <div v-if="!dd.is_folder"
                       :class="'elm text flex100row ats-c '+(($route.params.doc && $route.params.doc == dd.id)? 'active '+$store.commit(`set_current_doc`, dd.title):'') ">
                    <div style="padding-left: 20px; padding-right: 5px;">
                      <v-icon color=" color: #596283;" light>mdi-file-document-outline</v-icon>
                    </div>
                    <div @click='$router.push({ name: "document2", params: {level1: dd.parent_id,  doc: dd.id } });toggle_left(); $store.commit("set_current_doc", dd.title); ' class="cursor" v-html="dd.title"></div>
                    <div>
                      <v-menu right>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on" class="cursor" size="30">mdi-dots-vertical</v-icon>
                        </template>
                        <v-list>

                          <v-list-item class="hovered cursor">
                            <v-list-item-title @click='rename_item(dd.id, dd.title)'>Переименовать документ</v-list-item-title>
                          </v-list-item>
                          <v-list-item class="hovered cursor">
                            <v-list-item-title @click="delete_item(dd.id)">Удалить документ
                            </v-list-item-title>
                          </v-list-item>

                        </v-list>
                      </v-menu>
                    </div>
                  </div>
                </template>
                <!--  -->
              </div>
            </div>
          </div>
        </div>
        <div class="menu-btn">
          <v-icon @click="toggle_left()" color="#fff" large>mdi-menu</v-icon>
        </div>
      </div>
      <div class="flex1 right-pan">
        <doc v-if="$route.name == 'document2' || $route.name == 'document3'"></doc>
      </div>
    </div>
    <v-dialog
        v-model="dialog"

        max-width="360"
    >

      <v-card color="#eee">
        <v-card-title class="headline" style="background-color: #1d53b1; color: #fff">
          {{ dialog_vars.header }}
        </v-card-title>
        <v-card-text>
          <p style="padding: 20px 0 4px">{{ dialog_vars.title }}</p>
          <v-text-field v-model="dialog_vars.var" solo></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="green darken-1"
              text
              @click="dialog = false"
          >
            Отмена
          </v-btn>
          <v-btn v-if="dialog_vars.tis_folder"
                 color="green darken-1"
                 text
                 @click="(dialog_vars.is_rename)? rename_item_action() : save_folder()"
          > Сохранить
          </v-btn>
          <v-btn v-if="dialog_vars.type == 'doc'"
                 color="green darken-1"
                 text
                 @click="(dialog_vars.is_rename)? rename_item_action() : create_doc_action()"
          > Сохранить
          </v-btn>

        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import sha256 from "crypto-js/sha256";
import Base64 from "crypto-js/enc-base64";
import hmacSHA512 from "crypto-js/hmac-sha512";
import doc from "@/components/document.vue";

export default {
  name: 'maind',
  components: {doc},
  data() {
    return {
      selected_item: null,
      dialog: false,
      cat: [],
      dialog_vars: {},
      dialog_vars_default: {
        header: 'Создание нового раздела',
        parent_id: 0,
        title: 'Укажите название нового раздела',
        var: '',
        tis_folder: true,
        open: false,
        ord: 100,

      },

    }
  },
  computed: {
    catalog() {
      return this.$store.state.catalog;
    },
    titles() {
      return this.$store.state.db_titles;

    }
  },
  watch: {
    dialog(a) {
      if (a == false) {
        this.dialog_vars = {};
      }
    },
    titles(a) {
      let vm2 = this;
      vm2.levels();

    }

  },
  mounted() {
    let vm2 = this;
    setTimeout(() => {
      vm2.levels();
    }, 200)

  },
  methods: {
    toggle_left() {
      if (window.innerWidth > 720) return;
      let d = document.querySelector('.mhide');
      let dd = document.querySelector('.left-pan');
      if (d) {
         dd.classList.remove('mhide');
      } else {
        dd.classList.add('mhide');
      }

    },

    levels(parent = 0) {
      var vm2 = this;
      var a = [];
      var b = [];
      this.$nextTick(() => {
        vm2.titles.forEach(e => {
          if (vm2.$route.params.level1 == e.id) {
            e.open = true;
          }
          if (vm2.$route.params.level2 && vm2.$route.params.level2 == e.id) {
            e.open = true;
          }
        });
      });

      this.$nextTick(() => {
        var b = _.orderBy(vm2.titles, ['ord'], ['asc']);

        const nest = (items, id = 0, link = 'parent_id') =>
            items
                .filter(item => item[link] === id)
                .map(item => ({...item, children: nest(items, item.id)}));

        vm2.cat = nest(b);

      });


    },
    rename_item(id, title) {
      this.dialog_vars = {};
      for (let key in this.dialog_vars_default) {
        this.dialog_vars[key] = this.dialog_vars_default[key];
      }
      this.dialog_vars.header = "Переименование";
      this.dialog_vars.title = "Укажите новое название ";
      this.dialog_vars.id = id;
      this.dialog_vars.var = title;
      this.dialog_vars.is_rename = true;
      this.dialog_vars.parent = JSON.parse(id);
      this.dialog = true;
    //  console.log(this.dialog_vars);
    },
    rename_item_action() {
      let obj = this.titles;
      let vm2 = this;
      obj.forEach(e=> {
        if (vm2.dialog_vars.id == e.id) {
          e.title = vm2.dialog_vars.var;
        }
      })

      vm2.$store.dispatch("set_new_title", obj);
      vm2.dialog_vars = {};
      setTimeout(() => {
        vm2.dialog = false;
      }, 200)
    },
    set_open(id, open) {
      let obj = this.titles;
      let vm2 = this;
      obj.forEach(e=> {
        if (id == e.id) {
          e.open = open;
        }
      })

      vm2.$store.dispatch("set_new_title", obj);


    },
    delete_item(id) {
      if (confirm('Будет удален раздел/документ!')) {
        this.$store.dispatch("delete_item", id);
        setTimeout(()=> {
          location.href="/";
        },500);
      }
    },
    create_doc(n) {
  //    console.log(n);
      //   this.dialog_vars = Object.create(this.dialog_vars_default);
      for (let key in this.dialog_vars_default) {
        this.dialog_vars[key] = this.dialog_vars_default[key];
      }

      this.dialog_vars = {
        header: 'Создание документа',
        title: 'Укажите название нового документа',
        var: '',
        type: 'doc',
        parent: JSON.parse(n),
        is_rename: false,

      }
      this.dialog = true;
      //   console.log(this.dialog_vars);
    },
    create_doc_action() {
      let vm2 = this;
      let max_id = 1 + this.titles[this.titles.length - 1].id;
      let obj = {
        id: max_id,
        title: this.dialog_vars.var,
        parent_id: this.dialog_vars.parent,
        is_folder: false,
        open: false,
        ord: 1
      };
      this.$store.commit("add_new_record", obj);
      setTimeout(() => {
        axios.get(vm2.$store.state.api_path+'?f=create_empty_doc&doc_id='+max_id+'&user_id='+vm2.$store.state.user)
            .then(function (response) {
              if (response.data == 'ok') {
                vm2.dialog = false;
                vm2.dialog_vars = {};
                vm2.$router.push('/document/'+obj.parent_id+'/'+max_id);
              } else alert(response.data);

            })

      }, 200)


    },
    create_folder(n) {
  //    console.log(n);
      //   this.dialog_vars = Object.create(this.dialog_vars_default);
      for (let key in this.dialog_vars_default) {
        this.dialog_vars[key] = this.dialog_vars_default[key];
      }
      this.dialog_vars.parent = JSON.parse(n);
      this.dialog = true;

    },
    save_folder() {
      let vm2 = this;
      let max_id = 1 + this.titles[this.titles.length - 1].id;
      let obj = {
        id: max_id,
        title: this.dialog_vars.var,
        parent_id: this.dialog_vars.parent,
        is_folder: true,
        open: false,
        ord: 1
      };
      this.$store.commit("add_new_record", obj);
      setTimeout(() => {
        vm2.dialog = false;
      }, 200)
    },

    hash_gen() {
      var t = Date.now() + (Math.random() * 1000);
      const hashDigest = sha256(t);
      const hmacDigest = Base64.stringify(hmacSHA512(hashDigest, '' + t));
      return hmacDigest;
    }
  }
}
</script>


<style scoped lang="less">
.rot90 {
  transition: all 0.2s ease;
  transform: rotate(90deg);
}
</style>
