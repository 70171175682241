import Vue from 'vue'
import Vuex from 'vuex'
import {getDate} from "vuetify/lib/components/VCalendar/util/timestamp";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
      //api_path: 'http://192.168.0.104:8880/php/api.php',
       api_path: '/php/api.php',
    current_doc: '',
    defs: {},
    search_open: false,
    db_titles: [
     /* {id: 4, title: 'Заголовок 2', parent_id: 0, is_folder: true, open: false, ord: 4},
      {id: 5, title: 'Заголовок 2_1', parent_id: 4, is_folder: true, open: false, ord: 5},
      {id: 1, title: 'Заголовок 1', parent_id: 0, is_folder: true, open: false, ord: 1},
      {id: 2, title: 'Заголовок 1_1', parent_id: 1, is_folder: true, open: false, ord: 2},
      {id: 3, title: 'Заголовок 1_2', parent_id: 1, is_folder: true, open: false, ord: 3},
      {id: 6, title: 'Документ 1_1_1', parent_id: 2, is_folder: false, open: false, ord: 6},
      {id: 9, title: 'Документ 1_1_2', parent_id: 2, is_folder: false, open: false, ord: 9},
      {id: 7, title: 'Документ 2_1', parent_id: 4, is_folder: false, open: false, ord: 7},
      {id: 8, title: 'Документ 2_1_1', parent_id: 5, is_folder: false, open: false, ord: 8},*/
    ],
    db_docs: [
      {'doc_id': 7, text: 'doc 7', type: 'text', ord: 1, id: 1},
      {'doc_id': 8, text: 'doc 8', type: 'text', ord: 1, id: 2},
      {'doc_id': 9, text: 'doc 9', type: 'text', ord: 1, id: 3},
      {'doc_id': 6, text: 'doc 6', type: 'text', ord: 1, id: 4},

    ],
    catalog: [
     
    ],
    user: null

  },
  mutations: {
    do_search_open(state, a) {
      state.search_open = a;
    },
    get_titles(state, a) {
      state.db_titles = a;

    },
    add_new_record(state, a) {
      state.db_titles.push(a);
      this.dispatch('set_new_title', state.db_titles);
    },
    set_user(state,a) {
      state.user = a;
    },
    set_current_doc(state, title) {
      state.current_doc = title;
    }

  },
  actions: {
    sec_key(context, login) {
        return login+"duskpc";
    },
    set_new_title(context, obj) {
      axios(
          {
            method: 'post',
            url: this.state.api_path,
            data: {
              f: "set_new_title",
              obj: obj,
              user: this.state.user
            }
          })
          .then(function (response) {
            if (response.data == 'ok')
            context.dispatch('get_titles',[]);
            else alert('fail');
          })

    },

    get_titles(context) {
      function get_max_id(t) {
        let json = t;
        json.sort(function(a,b) {
          return a.id - b.id;
        })
        return json;
      }
      axios.get(this.state.api_path+"?f=get_titles&user_id="+this.state.user)
          .then(function (response) {
          context.commit("get_titles", response.data);
          })
    },
    delete_item(context, id) {
      axios.get(this.state.api_path+"?f=delete_item&id="+id+"&user="+this.state.user)
          .then(function (response) {
            if (response.data == 'ok')
            context.dispatch("get_titles");
            else {
              alert(response.data);
            }
          })
    }
  }
})
