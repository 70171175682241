<template>
  <v-app >
    <div class="flex100col" style="flex: 0; width: 100%; height: 100vh">
      <div class="flex100row flex-sb ats-c flex-wrap up-wrapper"
           style="padding: 8px 12px; color: #dedede; background-color: #1d53b1;">
        <div> BOOKSHELF SECURITY</div>
        <div>
          <form>
          <div v-if="!$store.state.user" class="up_form flex100row ats-c flex-wrap" style="justify-content: center">
            <div>
              <span class="hm"> Вход: </span>
              <input type="text" v-model.trim="user.login" placeholder="Login">
              <input id="pwd" type="password" v-model.trim="user.pass" placeholder="Password">
            </div>
            <div class="w">
              <v-btn @click="enter()" small class="normal">Вход</v-btn> &nbsp;
              <v-btn @click="registration()" small class="normal">Регистрация</v-btn>
            </div>

          </div>
          <div v-else>
            Вход произведен: User_id={{$store.state.user}}
            <v-btn small class="mx-2" @click="out(1); user.login=''; user.pass = ''">Выход</v-btn>
          </div>
        </form>
        </div>
        <div @click="$store.commit('do_search_open', true)">
          <v-icon color="#fff" >mdi-text-search</v-icon> - поиск
        </div>
        <div class="hm">
          <v-btn
              text
              color="#CCC"
          >
        <span class="mr-2">Old version
        </span>
            <v-icon>mdi-open-in-new</v-icon>
          </v-btn>
        </div>
      </div>
    </div>

    <div style="width: 100vw; overflow-x: hidden; flex: 1">
      <router-view  />
    </div>

  </v-app>
</template>

<script>
  let md5 = require('locutus/php/strings/md5')
  export default {
    name: 'App',

    data: () => ({
      me: '',
      user: {
        login: '',
        pass: '',
        search_open: false,
      }
    }),
    mounted() {
      let vm2 = this;
      if (getCookie('userId')) {

        this.$store.commit('set_user', getCookie('userId'));
        setTimeout(() => {
          vm2.$store.dispatch('get_titles');
        }, 300)

      } else {
        this.get_login_from_ip();
      }
      let d = document.getElementById('pwd');
      d.addEventListener('keypress', function(e) {
        if (e.keyCode == 13) {
          vm2.enter();
        }
      })
    },
    methods: {
      get_login_from_ip() {
        let vm2 = this;
        axios.get(vm2.$store.state.api_path+"?f=get_login_from_ip")
            .then(function (response) {
              vm2.user.login = response.data;
            })
      },
      out(n = 0) {
        deleteCookie('userId');
        deleteCookie('login');
        deleteCookie('hash');
        deleteCookie('hashp');
        this.$store.commit('set_user', null);
        if (n == 1) location.assign("/");
      },
      enter() {
        if (this.user.login && this.user.pass) {
          let vm2 = this;
          let key = this.user.login + ' ' + this.user.pass;
          this.out();
          axios(
              {
                method: 'post',
                url: vm2.$store.state.api_path,
                data: {
                  f: 'user_confirm',
                  mode: 'user',
                  login: md5(vm2.user.login)
                }
              }
          )
              .then(function (response) {
                if (response.data == '0') {
                  alert("Ошибка авторизации!");
                } else {
                  let decoded = response.data.enc;
                  //   let decoded = decodeURIComponent(response.data.pass);
                  let s = CryptoJS.AES.decrypt(decoded, key);
                  let obj = s.toString(CryptoJS.enc.Utf8);
                  if (obj == vm2.user.pass) {
                    setCookie('userId', response.data.user_id);
                    setCookie('login', vm2.user.login);
                    setCookie('hashp', md5(vm2.user.pass+"1"));
                    setCookie('hash', response.data.enc);
                    vm2.$store.commit('set_user', response.data.user_id);
                    setTimeout(() => {
                      location.reload()
                    }, 100)
                  }

                }
              })
        } else {
          alert('Укажите E-mail и пароль для входа!');
        }
      },
      registration() {
        if (this.user.login && this.user.pass) {
          let vm2 = this;
          let key = '';
          this.$store.dispatch('sec_key', this.user.login)
              .then(function (result) {
                key = result;
              });

          if (confirm('Региструясь на сайте, Вы должны помнить, что введенный Вами пароль не восстанавливается, и при его утере, все данные будут безвозвратно утеряны!!! ')) {
            setTimeout(() => {
              let enc = CryptoJS.AES.encrypt(this.user.pass, this.user.login + ' ' + this.user.pass).toString();
              //   let coded = encodeURIComponent(enc);
              let decoded = decodeURIComponent(enc);
              let s = CryptoJS.AES.decrypt(decoded, key);
              let obj = s.toString(CryptoJS.enc.Utf8);
              console.log(enc, key, obj);
              axios(
                  {
                    method: 'post',
                    url: vm2.$store.state.api_path,
                    data: {
                      f: 'user_confirm',
                      mode: 'new_user',
                      code: enc,
                      login: vm2.user.login
                    }
                  }
              )
                  .then(function (response) {
                    if (response.data == 'ok') {
                      alert('Регистрация успешна!');
                    } else {
                      alert('Пользователь с таким логином зарегистрирован!')
                    }
                  })

            }, 300);

          } else {
            alert('Укажите E-mail и пароль для регистрации!');
          }

        }

      }
    }
  }
</script>
<style scoped lang="less">
  .up-wrapper {

    div {
      transition: all 0.3s ease;
    }

    .hm {
      display: inline-block;
    }

    @media (max-width: 926px) {
      & > div {
        text-align: center;
        padding: 8px 5px;
        width: 100%;
      }

      .hm {
        display: none;
      }
    }
    @media (max-width: 720px) {
      flex-direction: column;
      &>div {
        width: 100%;
      }
    }
  }

  .up_form {
    & > div {
      padding: 5px 3px;
    }

    input {
      background: #eee;
      margin: 0 5px;
      padding: 2px 4px;
      font-size: 90%;
      width: 140px;
    }
  }
</style>
